import React, { useRef } from 'react'
import MainLayout from '../layouts/Main'
import '../template-styles/wkd-bluecore.scss'
import { graphql } from 'gatsby'
import Button from '../Components/Button/Button'
import DownloadForm from "../Components/Page/Resources/DownloadFormGetADemo/DownloadFormGetADemo";
import Divider from '../Components/General/Divider/Divider'
import LogoWallTicker from '../Components/General/LogoWallTicker/LogoWallTicker'
import Banner from '../Components/Header/Banner'

function WkdBluecore({data, props}) {
  const sections = data?.wordPress?.hPageTemplates?.nodes?.[0]?.hPages?.nodes[0].WkdBcFields?.wkdbcfields

  function getSEOForPage(data) {
    return data?.wordPress?.hPageTemplates?.nodes[0].hPages.nodes[0]
  }
  const pageSEO = getSEOForPage(data);
  console.log('pageSEO', pageSEO);
  //
  //

  const downloadFormRef = useRef(null)

  const handleScrollToDownloadForm = () => {
    downloadFormRef.current.scrollIntoView({behavior: 'smooth'})

    setTimeout(() => {
      const offset = 32; // The offset in pixels
      const elementPosition = downloadFormRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }, 750);
  }



  return (
    <MainLayout
    footerPattern="/images/patterns/pattern-02.svg"
    seoMeta={pageSEO}
    lang={"us"}
    languages={["us", "uk"]}
    defaultStickyMenu={true}
  >
    <Banner top='117px' />
        {sections.map((section, index) => {
          const type = section?.__typename
          switch(type) {
            case "WordPress_HPage_Wkdbcfields_Wkdbcfields_LandingSection":
              return (
                <div className='wkd-bluecore-wrapper' style={{backgroundColor: section?.backgroundColor || '#303d78'}}>
                  <div className='wkd-bluecore-container'>
                    <div className="header-desc-num-image-container">
                      <div className="header-desc-number-btn-container">
                      <h4>{section?.smallHeader}</h4>
                      <h2>{section?.mainHeader}</h2>
                      <div className="number-desc-wrapper">
                      {section?.numberAndDescription?.map((numberDesc) => (
                        <div className='number-desc-container'>
                          <h3>{numberDesc?.number}</h3>
                          <h6>{numberDesc?.description}</h6>
                        </div>
                      ))}
                      </div>
                      <a onClick={handleScrollToDownloadForm}><Button className="btn-fill">{section?.ctaText}</Button></a>
                      </div>
                    <div className="image-container">
                    {section?.image?.sourceUrl && <img className='image-container-image' src={section?.image?.sourceUrl} alt='infograph' />}
                    </div>
                  </div>
                  </div>
                </div>
              )
            case "WordPress_HPage_Wkdbcfields_Wkdbcfields_EcommRetailSection":
              return (
                <div className='wkd-bluecore-ecomm-retail-wrapper' style={{backgroundColor: section?.backgroundColor || '#f5ebe0'}}>
                  <div className="wkd-bluecore-ecomm-retail-container">
                    <h4>{section?.smallHeader}</h4>
                    <h2>{`See up to 4.75x lift in`}</h2>
                    <h2>{`triggered email revenue vs Bluecore`}</h2>
                    <div className="wkd-bluecore-ecomm-retail-image-container">
                      {section?.image?.sourceUrl && <img src={section?.image?.sourceUrl} alt='infograph' />}
                    </div>
                    <p>*Compares US performance of Bluecore abandonment triggered emails alone prior to adding Wunderkind</p>
                  </div>
                </div>
              )
            case "WordPress_HPage_Wkdbcfields_Wkdbcfields_IdentitySection":
              return (
                <div className='wkd-bluecore-identity-wrapper' style={{backgroundColor: section?.backgroundColor || '#191919'}}>
                  <div className="wkd-bluecore-identity-container">
                    <div className="wkd-bluecore-identity-header-desc-image-container">
                      <div className="wkd-bluecore-identity-header-desc-container">
                        <h2>{section?.mainHeader}</h2>
                        <h6>{section?.description}</h6>
                        <h6>{section?.descriptionContinued}</h6>
                      </div>
                      <div className="wkd-bluecore-identity-image-container">
                        {section?.image?.sourceUrl && <img src={section?.image?.sourceUrl} alt='infograph' />}
                      </div>
                    </div>
                    <div className='wkd-bluecore-identity-repeat-wrapper'>
                    {section?.imageHeaderLogoRepeat?.map((item) => (
                      <div className='wkd-bluecore-identity-repeat-container'>
                        <img src={item.image.sourceUrl} alt='infograph' />
                        <h4>{item?.header}</h4>
                        <h6>{item?.description}</h6>
                      </div>
                    ))}
                    </div>
                  </div>
                </div>
              )
            case "WordPress_HPage_Wkdbcfields_Wkdbcfields_UnlikeBluecoreSection":
              return (
                <div className='wkd-unlike-bluecore-wrapper' style={{backgroundColor: section?.backgroundColor || '#f5ebe0'}}>
                  <div className="wkd-unlike-bluecore-container">
                    <div className="wkd-unlike-bluecore-block-header-container">
                      <img src='/images/wkd-unlike-bluecore-block-pattern.svg' alt='block' />
                      <h2>{`Unlike Bluecore`}</h2>
                      <h2>{`Wunderkind guarantees`}</h2>
                      <h2>{`performance`}</h2>
                      <img src='/images/wkd-unlike-bluecore-block-pattern-2.svg' alt='block' />
                    </div>
                    <p>{section?.description}</p>
                    <p>{section?.descriptionContinued}</p>
                    <h3>{section?.secondaryHeader}</h3>
                    <p>{section?.secondaryHeaderDescription}</p>
                    {section?.imageAndText?.map((content, index) => (
                      <div className={`${content?.reverseOrder == 'Yes' ? 'wkd-unlike-bluecore-image-and-text-reverse' : 'wkd-unlike-bluecore-image-and-text'}`}>
                        <div className="wkd-unlike-bluecore-image-container">
                          {content?.image?.sourceUrl && (  <img src={content.image.sourceUrl} alt='' /> )}                      
                        </div>
                        <div className="wkd-unlike-bluecore-text-container">
                          <h3>{content?.header}</h3>
                          <p>{content?.description}</p>
                        </div>
                      </div>
                    ))}
                    <div className="wkd-unlike-bluecore-quote-section">
                      <div className="wkd-unlike-bluecore-quote-container">
                        <h6>Switched from bluecore to Wunderkind</h6>
                        <img src='/images/wkd-unlike-bluecore-Wolverine_Worldwide.png' alt='' />
                        <p>“We were able to increase the send volume of our site abandonment emails by 300% with Wunderkind."</p>
                        <span>Senior Director of Marketing</span>
                      </div>
                    <div className="wkd-unlike-bluecore-stat-section">
                      <div><span className='wkd-unlike-bluecore-stat-number'>15-20% lift</span> <br /><div className='wkd-unlike-bluecore-stat-number-desc'>in total revenue</div></div>
                      <div className="wkd-unlike-bluecore-stat-section-border"></div>
                      <div><span className='wkd-unlike-bluecore-stat-number'>300% lift</span> <br /><div className='wkd-unlike-bluecore-stat-number-desc'>in volume of website abandonment emails</div></div>
                      <div className="wkd-unlike-bluecore-stat-section-border"></div>
                    </div>
                    </div>
                </div>
                    </div>
              )
              case "WordPress_HPage_Wkdbcfields_Wkdbcfields_ImagesAndQuotesSections":
                return (
                  <>
                  {section?.imageAndText?.map((content) => (
                    <div className='images-and-quotes-section-wrapper'>
                      <div className='images-and-quotes-section-container'>
                        <div className="images-and-quotes-section-image-header-desc-container">
                          <div className="images-and-quotes-section-image-container">
                            <img src={content?.image?.sourceUrl} alt='' />
                          </div>
                          <div className="images-and-quotes-section-header-desc-container">
                            <h2>{content?.header}</h2>
                            <p>{content?.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

{section?.quoteAndStatsReversed?.map((content) => (
                  <div className='images-and-quotes-section-wrapper'>
                    <div className='images-and-quotes-section-container'>
                    <div className="images-and-quotes-stats-logo-number-desc-container">
                      <div className="images-and-quotes-stats-logo-container">
                        <img src={content?.logo?.sourceUrl} alt='' />
                          <p>{content?.quote}</p>
                          <h6>{content?.position}</h6>
                      </div>
                      <div className="images-and-quotes-stats-number-container">
                        <h2>{content?.number}</h2>
                        <h6>{content?.numberDescription}</h6>
                        <div className='images-and-quotes-stats-number-border' />
                        <h2>{content?.number2}</h2>
                        <h6>{content?.numberDescription2}</h6>
                        <div className='images-and-quotes-stats-number-border' />
                      </div>
                    </div>
                  </div>
                </div>
                  ))}
                  {section?.imageAndTextReversed?.map((content) => (
                  <div className='images-and-quotes-section-wrapper'>
                    <div className='images-and-quotes-section-container'>
                      <div className="images-and-quotes-section-image-header-desc-container">
                        <div className="images-and-quotes-section-image-container">
                          <img src={content?.image?.sourceUrl} alt='' />
                        </div>
                        <div className="images-and-quotes-section-header-desc-container">
                          <h2>{content?.header}</h2>
                          <p>{content?.description}</p>
                        </div>
                      </div>
                    </div>
                    </div>
                  ))}
                  {section?.quoteAndStats?.map((content) => (
                  <div className='images-and-quotes-section-wrapper'>
                    <div className='images-and-quotes-section-container'>
                      <div className="images-and-quotes-stats-logo-number-desc-container">
                        <div className="images-and-quotes-stats-logo-container">
                          <img src={content?.logo?.sourceUrl} alt='' />
                            <p>{content?.quote}</p>
                            <h6>{content?.position}</h6>
                        </div>
                        <div className="images-and-quotes-stats-number-container">
                          <h2>{content?.number}</h2>
                          <h6>{content?.numberDescription}</h6>
                          <div className='images-and-quotes-stats-number-border' />
                          <h2>{content?.number2}</h2>
                          <h6>{content?.numberDescription2}</h6>
                          <div className='images-and-quotes-stats-number-border' />
                        </div>
                      </div>
                    </div>
                    </div>
                  ))}                  

                  </>
                )
                case "WordPress_HPage_Wkdbcfields_Wkdbcfields_TableComparisonSection":
                  return (
                    <div className='table-section-wrapper' style={{backgroundColor: section?.wrapperBackgroundColor || `#303d78`}}>
                    <div className='table-section-container'>
                      <h4>{section?.smallHeader}</h4>
                      <h2>{section?.mainHeader}</h2>
                      <div className="table-section-table-container">
                        <img src='/images/wkd-vs-bluecore/comparison-table-updated.svg' alt='infograph' />
                      </div>
                      {/* <table className='main-table'>
                        <thead>
                          <tr>
                            <th></th>
                            <th className='highlighted table-bigger-header raised'>Wunderkind</th>
                            <th>Bluecore</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Triggered email results</td>
                            <td className='highlighted '><span className='table-bigger-number'>4x</span> <br />higher than Bluecore</td>
                            <td>-</td>
                          </tr>
                          <tr className='alternate-color'>
                            <td>Text and email list growth</td>
                            <td className='highlighted'><span className='table-bigger-number'>2 - 3x</span> <br />higher than Bluecore</td>
                            <td>-</td>
                          </tr>
                          <tr>
                            <td>Anonymous user identification</td>
                            <td className='highlighted'><span className='table-bigger-number'>3-6x</span> <br />higher than Bluecore</td>
                            <td>-</td>
                          </tr>
                          <tr className='alternate-color'>
                            <td>Performance guarantee</td>
                            <td className='highlighted'><span className='table-bigger-number'>Yes</span> <br />Contractually guarantees better performance based on last-click revenue from defined triggered emails</td>
                            <td>No</td>
                          </tr>
                          <tr>
                            <td>Campaign types</td>
                            <td className='highlighted table-description'>Wunderkind drives 10-25% of your total digital eCommerce revenue with only 6 campaigns. <br /><br /> Cart Abandonment | Product Abandonment |Category Abandonment  | Price Drop | 
Back in Stock | Low in Stock</td>
                            <td className='table-description'>Bluecore requires more triggered and non-triggered campaigns to achieve performance <br /><br />Cart Abandonment | Product Abandonment |  Category Abandonment | Price Drop | Back in Stock | Low in Stock | Search Abandonment | Post-purchase At-risk |  New Arrivals | Non-triggered, marketing emails and text</td>
                          </tr>
                          <tr className='alternate-color'>
                            <td>Identity database</td>
                            <td className='highlighted table-description'>1 billion opted-in profiles <br /> 1 trillion daily events <br /> 9 billion devices identified</td>
                            <td>900 million email addresses <br /> 10 billion daily events.</td>
                          </tr>
                          <tr>
                            <td>Cookie-based?</td>
                            <td className='highlighted'><span className='table-bigger-number'>Non-cookie reliant</span> <br /> data never expires</td>
                            <td>Yes <br />Data expires with time</td>
                          </tr>
                        </tbody>
                      </table> */}
                    </div>
        </div>
                  );
                  case "WordPress_HPage_Wkdbcfields_Wkdbcfields_BrandsSection":
                    return (
                      <>
                      <div className='wkd-bluecore-brands-wrapper' style={{backgroundColor: section?.wrapperBackgroundColor || `#191919`}}>
                        <div className='wkd-bluecore-brands-container'>
                          <h2>{section?.mainHeader}</h2>
                          <LogoWallTicker logos={section.logos} />
                          <p>{section?.description}</p>
                        </div>
                      </div>
                      <div ref={downloadFormRef} className='wkd-bluecore-form-wrapper' style={{backgroundColor: section?.wrapperBackgroundColor || `#f5ebe1`}}>
                        <div className='wkd-bluecore-form-container'>
                          <div className="wkd-bluecore-form-header-image-desc-container">
                            <div className="wkd-bluecore-header-desc-image-container">
                              <h2>White glove migration from Bluecore</h2>
                              <p>We’ll only ask you to place a pixel on your site. The Wunderkind team takes care of strategy, copywriting, development, quality assurance testing, and campaign activation.</p>
                              <img src='/images/wkd-bluecore-artist-innovator.svg' />
                              </div>
                              <div className="wkd-bluecore-download-form-container">
                                <DownloadForm
                                  marketoFormSubtitle="Tell us a bit about yourself, <br/> and we’ll get in touch shortly."
                                  formId={`4838`}
                                  />
                                </div>
                            </div>
                        </div>
                      </div>
                            </>
                    )
                    case "WordPress_HPage_Wkdbcfields_Wkdbcfields_Divider":
                      return (
                        <Divider
                        additionalClass={section.additionalclass}
                        key={"section-" + index}
                        bgColor={section.backgroundColor}
                        maskColor={section.maskColor}
                        direction={section.position.toLowerCase() + "-" + section.orientation.toLowerCase()}
                        />
                      )

                           
            }

        })}
    </MainLayout>
  )
}

export const query = graphql`
query WkdBluecoreQuery {
  wordPress {
    hPageTemplates(where: {slug: "wkd-bluecore-competition"}) {
      nodes {
        hPages {
          nodes {
            title
            WkdBcFields {
              wkdbcfields {
                ... on WordPress_HPage_Wkdbcfields_Wkdbcfields_LandingSection {
                  ctaHref
                  ctaText
                  image {
                    sourceUrl
                  }
                  mainHeader
                  smallHeader
                  numberAndDescription {
                    number
                    description
                  }
                }
                ... on WordPress_HPage_Wkdbcfields_Wkdbcfields_EcommRetailSection {
                  fieldGroupName
                  mainHeader
                  smallHeader
                  image {
                    sourceUrl
                  }
                }
                ... on WordPress_HPage_Wkdbcfields_Wkdbcfields_IdentitySection {
                  wrapperBackgroundColor
                  mainHeader
                  description
                  descriptionContinued
                  image {
                    sourceUrl
                  }
                  imageHeaderLogoRepeat {
                    header
                    description
                    image {
                      sourceUrl
                    }
                  }
                }
                ... on WordPress_HPage_Wkdbcfields_Wkdbcfields_UnlikeBluecoreSection {
                  description
                  descriptionContinued
                  blockImage {
                    sourceUrl
                  }
                  blockImage2 {
                    sourceUrl
                  }
                  mainHeader
                  secondaryHeader
                  secondaryHeaderDescription
                  imageAndText{
										image{
											sourceUrl
                    }
                    header
                    description
                    reverseOrder
                  }
                }
                ... on WordPress_HPage_Wkdbcfields_Wkdbcfields_ImagesAndQuotesSections {
                  fieldGroupName
                  imageAndText {
                    header
                    description
                    image {
                      sourceUrl
                    }
                  }
                  imageAndTextReversed {
                    header
                    description
                    image {
                      sourceUrl
                    }
                  }
                  quoteAndStats {
                    number
                    number2
                    numberDescription
                    numberDescription2
                    position
                    quote
                    logo {
                      sourceUrl
                    }
                  }
                  quoteAndStatsReversed {
                    number
                    number2
                    numberDescription
                    numberDescription2
                    position
                    quote
                    logo {
                      sourceUrl
                    }
                  }
                }

                ... on WordPress_HPage_Wkdbcfields_Wkdbcfields_TableComparisonSection {
                  mainHeader
                  smallHeader
                }
                ... on WordPress_HPage_Wkdbcfields_Wkdbcfields_BrandsSection {
                  description
                  fieldGroupName
                  mainHeader
                  logos {
                    logo {
                      sourceUrl
                    }
                  }
                }
                ... on WordPress_HPage_Wkdbcfields_Wkdbcfields_Divider {
                  fieldGroupName
                  backgroundColor
                  maskColor
                  orientation
                  position
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export default WkdBluecore